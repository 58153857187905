// Import statements
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_ENERGIEVERBRAEUCHE, GET_GESAMTVERBRAEUCHE } from './graphql';
import { useParams } from "react-router-dom";
import LoadingAnimation from '../Utils/LoadingAnimation';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 10,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30,
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 12,
    },
    row: {
        flexDirection: 'row',
        padding: 5,
    },
    evenRow: {
        backgroundColor: '#f0f0f0',
    },
    oddRow: {
        backgroundColor: '#ffffff',
    },
    cell: {
        padding: 2,
    },
    name: {
        width: '20%',
    },
    parzelle: {
        width: '20%',
    },
    strom: {
        width: '15%',
    },
    gas: {
        width: '15%',
    },
    wasser: {
        width: '15%',
    },
    pachtende: {
        width: '15%',
    },
});

const Energieverbraeuche = () => {
    const { loading: loadingEnergie, error: errorEnergie, data: dataEnergie } = useQuery(GET_ENERGIEVERBRAEUCHE);
    const { loading: loadingGesamt, error: errorGesamt, data: dataGesamt } = useQuery(GET_GESAMTVERBRAEUCHE);

    if (loadingEnergie || loadingGesamt) return <LoadingAnimation />;
    if (errorEnergie) return <Text>Error loading Energieverbraeuche data</Text>;
    if (errorGesamt) return <Text>Error loading Gesamtverbraeuche data</Text>;

    const params = useParams();
    const abrechnungsperiode = parseInt(params.id);

    const transformData = (data) => {
        const groupedData = {};
    
        data.forEach((entry) => {
            const { paechter, parzelle, energieart, verbrauch, pacht, verwaltungsparzelle, zaehler } = entry;
    
            // Set the key based on conditions
            let key = pacht?.kontierungsnummer || parzelle;
            if (verwaltungsparzelle && parzelle === "Fiktive Zähler" && zaehler?.zaehlernummer) {
                key = zaehler.zaehlernummer; // Use zaehlernummer as the key
            }
    
            // Initialize the group if not already present
            if (!groupedData[key]) {
                groupedData[key] = {
                    paechter: verwaltungsparzelle && parzelle === "Fiktive Zähler" && zaehler?.zaehlernummer 
                        ? zaehler.zaehlernummer // Use zaehlernummer for "Name, Vorname"
                        : paechter || parzelle,
                    parzelle,
                    stromverbrauch: 0,
                    gasverbrauch: 0,
                    wasserverbrauch: 0,
                    pachtende: pacht?.pachtende || null,
                };
            }
    
            // Accumulate energy consumption
            if (energieart === "Strom") groupedData[key].stromverbrauch += verbrauch;
            if (energieart === "Gas") groupedData[key].gasverbrauch += verbrauch;
            if (energieart === "Wasser") groupedData[key].wasserverbrauch += verbrauch;
        });
    
        // Convert to array and sort by paechter (Name, Vorname) / key
        return Object.values(groupedData).sort((a, b) => {
            const nameA = a.paechter.toString().toLowerCase();
            const nameB = b.paechter.toString().toLowerCase();
            return nameA.localeCompare(nameB); // Sort alphabetically
        });
    };
    
    

    // Transform the data for energy consumption
    const energieverbraeucheList = transformData(
        dataEnergie.energieverbraeuche.filter(
            (entry) => entry.abrechnungsperiode === abrechnungsperiode && !entry.verwaltungsparzelle
        )
    ).sort((a, b) => a.paechter.localeCompare(b.paechter));

    // Add Verwaltungsparzelle data
    const verwaltungsparzelleData = transformData(
        dataEnergie.energieverbraeuche.filter(
            (entry) => entry.abrechnungsperiode === abrechnungsperiode && entry.verwaltungsparzelle
        )
    );

    // Add Gesamtverbraeuche data
    const gesamtverbrauch = dataGesamt?.gesamtverbraeuche
        .filter((item) => item.abrechnungsperiode === abrechnungsperiode)
        .map((item) => ({
            paechter: 'Gesamtsumme',
            parzelle: null,
            stromverbrauch: item.gesamtverbrauchStrom,
            gasverbrauch: item.gesamtverbrauchGas,
            wasserverbrauch: item.gesamtverbrauchWasser,
            pachtende: null,
        })) || [];

    // Combine data into final list
    energieverbraeucheList.push({});
    energieverbraeucheList.push({paechter:'Verwaltungsparzellen'});
    energieverbraeucheList.push(...verwaltungsparzelleData);
    energieverbraeucheList.push({});
    energieverbraeucheList.push(gesamtverbrauch[0]);

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
    };

    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        {/* Table Header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.name]}>Name, Vorname</Text>
                            <Text style={[styles.cell, styles.header, styles.parzelle]}>Parzelle</Text>
                            <Text style={[styles.cell, styles.header, styles.strom]}>Strom (kWh)</Text>
                            <Text style={[styles.cell, styles.header, styles.gas]}>Gas (m³)</Text>
                            <Text style={[styles.cell, styles.header, styles.wasser]}>Wasser (m³)</Text>
                            <Text style={[styles.cell, styles.header, styles.pachtende]}>Pachtende</Text>
                        </View>

                        {/* Table Rows */}
                        {energieverbraeucheList.map((item, index) => (
                            <View key={index} style={[styles.row, index % 2 === 0 ? styles.evenRow : styles.oddRow]}>
                                <Text style={[styles.cell, styles.name]}>{item?.paechter}</Text>
                                <Text style={[styles.cell, styles.parzelle]}>{item?.parzelle}</Text>
                                <Text style={[styles.cell, styles.strom]}>{item?.stromverbrauch}</Text>
                                <Text style={[styles.cell, styles.gas]}>{item?.gasverbrauch}</Text>
                                <Text style={[styles.cell, styles.wasser]}>{item?.wasserverbrauch}</Text>
                                <Text style={[styles.cell, styles.pachtende]}>{formatDate(item?.pachtende)}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default Energieverbraeuche;

//import { useQuery, gql, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';


export const GET_PACHT = gql`
    query GetPacht {
        pacht{
            id
            parzelle{
                id
                standort
                parzellengroesse
                stellplatzparzelle
                verwaltungsparzelle
            }
            paechter {
                id
                name
                vorname
                anrede
                strasse
                plz
                ort
            }
            pachtbeginn
            pachtpreis
            pachtpreisFolgejahr
            pachtende
            kontierungsnummer
            aConto
            vormerkungZahlungsmodus
        }
    }
`;

export const GET_ONE_PACHT = gql`
    query GetOnePacht($pachtID: ID!) {
        onePacht(pachtId: $pachtID) {
            id
            parzelle{
                id
                standort
                parzellengroesse
                stellplatzparzelle
                verwaltungsparzelle
            }
            paechter {
                id
                name
                vorname
                anrede
                strasse
                plz
                ort
            }
            pachtbeginn
            pachtpreis
            pachtpreisFolgejahr
            pachtende
            kontierungsnummer
            aConto
            vormerkungZahlungsmodus
        }
    }
`;


export const GET_AKTUELLE_PACHTEN = gql`
    query GetAktuellePachten {
        aktuellePachten{
            id
            parzelle{
                id
                standort
                parzellengroesse
                stellplatzparzelle
                verwaltungsparzelle
            }
            paechter {
                id
                name
                vorname
            }
            pachtbeginn
            pachtpreis
            pachtpreisFolgejahr
            pachtende
            kontierungsnummer
            aConto
            vormerkungZahlungsmodus
        }
    }
`;


export const CREATE_PACHT = gql`
    mutation CreatePacht(
        $parzelle: ID!,
        $paechter: ID!,
        $pachtbeginn: Date!,
        $pachtende: Date,
        $aConto: Boolean!,
        ) {
        createPacht(
            parzelle: $parzelle,
            paechter: $paechter,
            pachtbeginn: $pachtbeginn,
            pachtende: $pachtende,
            aConto: $aConto,
             ) {
                id
                parzelle
                paechter
                pachtbeginn
                pachtpreis
                pachtende
                aConto
        }
        
    }
`;



export const UPDATE_PACHT = gql`
    mutation UpdatePacht(
        $id: ID!,
        $parzelle: ID!,
        $paechter: ID!,
        $pachtbeginn: Date!,
        $pachtende: Date,
        $aConto: Boolean!,
        ) {
            updatePacht(
                id: $id,
                parzelle: $parzelle,
                paechter: $paechter,
                pachtbeginn: $pachtbeginn,
                pachtende: $pachtende,
                aConto: $aConto,
                ) {
                    id
                    parzelle
                    paechter
                    pachtbeginn
                    pachtpreis
                    pachtende
                    aConto
        }
    }
`;

export const UPDATE_PREISE_BESTANDSPACHTEN = gql`
    mutation UpdatePreiseBestandspachten {
        updatePreiseBestandspachten {
                result
        }
    }
`;



export const UPDATE_ZAHLUNGSMODUS = gql`
    mutation UpdateZahlungsmodus {
        updateZahlungsmodus {
                result
        }
    }
`;



export const DELETE_PACHT = gql`
    mutation DeletePacht($id: ID!){
        deletePacht(id: $id) {
            ok
        }
    }
`;

import * as React from 'react';
import { useQuery } from '@apollo/client';

import {GET_ENERGIEVERBRAEUCHE} from './graphql'
import Title from '../Utils/Title'
import LoadingAnimation from '../Utils/LoadingAnimation';

import AlertMessage from '../Alerts/AlertMessage';

import { useNavigate } from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Container from '@mui/material/Container';

import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';



function formatDate(date) {
    if(date.value == null) {
        return;
    } else {
        date = date.value.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}

function formatBool(val) {
    //console.log('Wert: ' + val.value);
    //if(val == true) {
    if(val.value) {
        return 'Ja';
    } else {
        return 'Nein';
    }
}


const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);





export default function ListEnergieverbraeuche() {
    
    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');
    
    const [loadingState, setLoadingState] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_ENERGIEVERBRAEUCHE, {
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    console.log(data);

    const verbraeucheList = data? data.energieverbraeuche.map(item => {
        const id = item.id;
        const zaehlernummer = item.zaehlernummer;
        const parzelle = item.parzelle;
        const verwaltungsparzelle = item.verwaltungsparzelle;
        const paechter = item.paechter;
        const energieart = item.energieart;
        const abrechnungsperiode = item.abrechnungsperiode;
        const verbrauch = item.verbrauch;
        const hinweis = item.hinweis;
        return { 
            id,
            zaehlernummer,
            parzelle,
            verwaltungsparzelle,
            paechter,
            energieart,
            abrechnungsperiode,
            verbrauch,
            hinweis,
        };
    }) : '';

    const rows = verbraeucheList;

    const navigate = useNavigate();
    

    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 70 },
        { field: 'abrechnungsperiode', headerName: 'Abrechnungsperiode', headerAlign: 'center', width: 170 },
        { field: 'zaehlernummer', headerName: 'Zähler-Nr.', width: 140, },
        { field: 'parzelle', headerName: 'Parzelle', width: 150, },
        { field: 'verwaltungsparzelle', headerName: 'Verwaltungsparzelle', width: 150, type: 'boolean', valueFormatter: (params) => formatBool(params) },
        { field: 'paechter', headerName: 'Pächter', width: 200, },
        { field: 'energieart', headerName: 'Energieart', width: 100 },
        { field: 'verbrauch', headerName: 'Verbrauch', width: 100, type: 'number' },
        { field: 'hinweis', headerName: 'Hinweis', width: 800 },
    ];
    
    return (
        <>
        
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Energieverbräuche</Title>

                    <div className="link-button-container">
                        <div className='link-buttons'>
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/energie/energieverbraeuche/berechnen" startIcon={<CachedIcon />}>
                                Verbräuche neu berechnen
                            </Button>
                            
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/energie/energiepreise">
                                Energiepreise
                            </Button>

                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/energie/zaehlerstaende">
                                Zählerstände
                            </Button>
                        
                            <Button color="primary" variant="contained" className="link-button" component={Link} to="/energie/zaehler">
                                Zähler
                            </Button>
                        </div>
                    </div>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'abrechnungsperiode', sort: 'desc' }],
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                checkboxSelection
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                loading={loadingState}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );

}
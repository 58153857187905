import * as React from 'react';
import { useQuery } from '@apollo/client';

import {GET_ZAHLUNGEN, DELETE_ZAHLUNG} from './graphql'
import Title from '../Utils/Title'
import LoadingAnimation from '../Utils/LoadingAnimation';

import ContextMenu from './ContextMenu';
import DeleteZahlung from './Delete';
import AlertMessage from '../Alerts/AlertMessage';

import { Link } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { useNavigate } from "react-router-dom";

import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';



function formatDate(date) {
    if(date.value == null) {
        return;
    } else {
        date = date.value.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}

function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
        maximumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
    }).format(number);
}

function formatPaechter(paechter) {
    console.log(paechter);
    //return paechter.value[0].name + ", " + paechter.value[0].vorname;
    //return 'TEST';
    return paechter.value[0].name;
    //return paechter.value[0].vorname + paechter.value[0].name;
    /*
    return (
            <Link to={`/paechter/${paechter.value[0].id}/show`}>
                {paechter.value[0].name} 
            </Link>
    )
    */
}


//const loads = false;
var loads=false;


const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);





export default function ListZahlungen() {
    
    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeleteParzelle' of child 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');
    
    //const rows = GetZahlungen();
    //console.log(rows);
    //console.log(rows[1].betrag);

    const navigate = useNavigate();

    
    
    const [loadingState, setLoadingState] = React.useState(true);

    const { loading, error, data, refetch } = useQuery(GET_ZAHLUNGEN, {
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });

    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }

    const zahlungsList = data? data.zahlungen.map(item => {
        const id = item.id;
        const betrag = item.betrag;
        const buchungsdatum = item.buchungsdatum;
        const paechter = item.pacht.paechter.name + ', ' + item.pacht.paechter.vorname;
        const zahlungsart = item.zahlungsart;
        const kontierungsnummer  = item.pacht.kontierungsnummer;
        const hinweis = item.hinweis;
        
        let zahlungsgrund = '';
        if (item.ausgleichszahlung) {
            zahlungsgrund = 'Ausgleichszahlung';
        } else if (item.sonstigeRechnung) {
            zahlungsgrund = 'Sonstige Rechnung';
        } else if (item.pachtzahlung) {
            zahlungsgrund = 'Pacht';
        } else if (item.betrag > 0) {
            zahlungsgrund = 'Abschlag'
        } else (zahlungsgrund = 'Auszahlung/Gutschrift')
        
        return { 
            id,
            betrag,
            buchungsdatum,
            paechter,
            zahlungsart,
            kontierungsnummer,
            hinweis,
            zahlungsgrund
        };
    }) : '';

    const rows = zahlungsList;


    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 80 },
        { field: 'betrag', headerName: 'Betrag (EUR)', width: 150, type: 'number', valueFormatter: (params) => formatCurrency(params.value), },
        { field: 'buchungsdatum', headerName: 'Buchungsdatum', width: 150, type: 'date', valueFormatter: (params) => formatDate(params), align: 'center' },
        { field: 'paechter', headerName: 'Pächter', width: 230 },
        { field: 'zahlungsart', headerName: 'Zahlungsart', width: 200 },
        { field: 'zahlungsgrund', headerName: 'Zahlungsgrund', width: 200 },
        { field: 'kontierungsnummer', headerName: 'Kontierungsnummer', width: 200 },
        { field: 'hinweis', headerName: 'Hinweis', width: 150 },
        {
            field: "actions",
            headerName:
                <>
                <Tooltip title="Zahlung anlegen">
                    <Link to={`/zahlung/create`}>
                        <IconButton>
                            <AddBoxIcon
                                variant='filled'
                                
                                color='action'
                                sx={{
                                    '&:hover': {
                                        color:'#233044'
                                    },
                                    marginLeft: '0.1rem',
                                    fontSize: '1.25rem'
                                }}
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
                </>,
            
            description: "",
            sortable: false,
            filterable: false,
            width: 100,
            renderCell: (params) => {
                return (
                    <ContextMenu
                        params={params}
                        assignAlertMessage={(alertMessage) => setAlertMessage(alertMessage)}
                        assignAlertSeverity={(alertSeverity) => setAlertSeverity(alertSeverity)}
                        handleOpenAlert={() => {setOpenAlert(true) }}
                    />
                );
            }
        }
    ];
    
    return (
        <>
        
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',                            
                        }}
                    >
                    <Title>Zahlungen</Title>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'id', sort: 'desc' }],
                                    },
                                }}
                                
                                rows={rows}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                checkboxSelection
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                //loading={loads}
                                loading={loading}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                            />
                        </ThemeProvider>
                    </div>    
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        </>
    );

}
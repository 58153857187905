import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate, Link } from "react-router-dom";

import {GET_ZAHLUNGEN_OF_PACHT_THIS_YEAR, GET_ZAHLUNGEN_OF_PACHT_LAST_YEAR } from '../../Zahlungen/graphql';
import { GET_ENDSALDO_VORJAHR, GET_ENDSALDO_VORVORJAHR } from '../../Gesamtrechnungen/graphql';
import Title from '../../Utils/Title';
import LoadingAnimation from '../../Utils/LoadingAnimation';

import ContextMenu from '../../Zahlungen/ContextMenu';
import DeleteZahlung from '../../Zahlungen/Delete';
import AlertMessage from '../../Alerts/AlertMessage';

import { useMutation } from '@apollo/client';

import { DataGrid } from '@mui/x-data-grid';
import { deDE as coredeDE } from '@mui/material/locale';
import { deDE as datagriddeDE } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddBoxIcon from '@mui/icons-material/AddBox';



function formatDate(date) {
    if(date.value == null) {
        return;
    } else {
        date = date.value.split("-");
        date = date[2] + "." + date[1] + "." + date[0];
        return date;
    }
}

function formatCurrency(number) {
    return new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
        maximumFractionDigits: 2, // Zeige immer zwei Dezimalstellen
    }).format(number);
}

function formatPaechter(paechter) {
    console.log(paechter);
    return paechter.value[0].name;
}


//const loads = false;
var loads=false;


const lighttheme = createTheme(
    {
        palette: {
            mode: 'light',
        },
    },
    coredeDE,
    datagriddeDE,
);



function GetEndsaldoVorjahr(pachtID) {
    const { loading, error, data } = useQuery(GET_ENDSALDO_VORJAHR, {
        variables: { pachtID },
        onCompleted: () => {
            console.log('Query completed');
        }
    });

    if (loading) {
        return { loading: true, data: null, error: null };
    }
    if (error) {
        return { loading: false, data: null, error: error.message };
    }
    return { loading: false, data: data.endsaldoVorjahr, error: null };
}


function GetEndsaldoVorvorjahr(pachtID) {
    const { loading, error, data } = useQuery(GET_ENDSALDO_VORVORJAHR, {
        variables: { pachtID },
        onCompleted: () => {
            console.log('Query completed');
        }
    });

    if (loading) {
        return { loading: true, data: null, error: null };
    }
    if (error) {
        return { loading: false, data: null, error: error.message };
    }
    return { loading: false, data: data.endsaldoVorvorjahr, error: null };
}


function GetZahlungenOfPachtThisYear(pachtID) {
    const { loading, error, data, refetch } = useQuery(GET_ZAHLUNGEN_OF_PACHT_THIS_YEAR, {
        variables: { pachtID },
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });
    
    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }
    
    const zahlungsList = data? data.zahlungenOfPachtThisYear.map(item => {
        const id = item.id;
        const betrag = item.betrag;
        const buchungsdatum = item.buchungsdatum;
        const paechter = item.pacht.paechter.name + ', ' + item.pacht.paechter.vorname;
        const zahlungsart = item.zahlungsart;
        const kontierungsnummer  = item.pacht.kontierungsnummer;
        const hinweis = item.hinweis;
        
        let zahlungsgrund = '';
        if (item.ausgleichszahlung) {
            zahlungsgrund = 'Ausgleichszahlung';
        } else if (item.sonstigeRechnung) {
            zahlungsgrund = 'Sonstige Rechnung';
        } else if (item.pachtzahlung) {
            zahlungsgrund = 'Pacht';
        } else if (item.betrag > 0) {
            zahlungsgrund = 'Abschlag'
        } else (zahlungsgrund = 'Auszahlung/Gutschrift')
        
        return { 
            id,
            betrag,
            buchungsdatum,
            paechter,
            zahlungsart,
            kontierungsnummer,
            hinweis,
            zahlungsgrund
        };
    }) : [];

    return { loading: false, data: zahlungsList, error: null };
}


function GetZahlungenOfPachtLastYear(pachtID) {
    const { loading, error, data, refetch } = useQuery(GET_ZAHLUNGEN_OF_PACHT_LAST_YEAR, {
        variables: { pachtID },
        onCompleted: () => {
            setLoadingState(false); // Set loading to false when data fetching is completed
        },
    });
    
    if (error) {
        return <p>Error :(</p>; // Show an error message if there's an error
    }
    
    const zahlungsList = data? data.zahlungenOfPachtLastYear.map(item => {
        const id = item.id;
        const betrag = item.betrag;
        const buchungsdatum = item.buchungsdatum;
        const paechter = item.pacht.paechter.name + ', ' + item.pacht.paechter.vorname;
        const zahlungsart = item.zahlungsart;
        const kontierungsnummer  = item.pacht.kontierungsnummer;
        const hinweis = item.hinweis;
        
        let zahlungsgrund = '';
        if (item.ausgleichszahlung) {
            zahlungsgrund = 'Ausgleichszahlung';
        } else if (item.sonstigeRechnung) {
            zahlungsgrund = 'Sonstige Rechnung';
        } else if (item.pachtzahlung) {
            zahlungsgrund = 'Pacht';
        } else if (item.betrag > 0) {
            zahlungsgrund = 'Abschlag'
        } else (zahlungsgrund = 'Gutschrift')
        
        return { 
            id,
            betrag,
            buchungsdatum,
            paechter,
            zahlungsart,
            kontierungsnummer,
            hinweis,
            zahlungsgrund
        };
    }) : [];

    return { loading: false, data: zahlungsList, error: null };
}





export default function ListZahlungenOfPacht() {
    
    // Alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    // useState to get alert message and severity from child 'DeleteParzelle' of child 'ContextMenu' of this component
    // instead of using useState with an object, we use one useState for each variable of the alert message
    // both versions are stored in nextcloud
    // We don't use a variable duration here, because in Alert.js variables cannot be passed to useEffect (only with the useState
    // variables in the array, so we differentiate in useEffect the severity for the duration/timeout of the alert message)
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertSeverity, setAlertSeverity] = React.useState('');
    
    const navigate = useNavigate();

    const params = useParams();
    const pachtID = params.id;

    const endsaldoVorjahr = GetEndsaldoVorjahr(pachtID).data;

    const endsaldoVorvorjahr = GetEndsaldoVorvorjahr(pachtID).data;
    
    const [loadingState, setLoadingState] = React.useState(true);

    const zahlungsListThisYear = GetZahlungenOfPachtThisYear(pachtID).data;
    const loadingOfGetZahlungenOfPachtThisYear = GetZahlungenOfPachtThisYear.loading

    const zahlungsListLastYear = GetZahlungenOfPachtLastYear(pachtID).data;
    const loadingOfGetZahlungenOfPachtLastYear = GetZahlungenOfPachtLastYear.loading

    
    // calculate total sum of betrag
    const totalBetragThisYear = zahlungsListThisYear.reduce((sum, item) => sum + parseFloat(item.betrag), 0);
    const totalBetragLastYear = zahlungsListLastYear.reduce((sum, item) => sum + parseFloat(item.betrag), 0);

    const currentYear = new Date().getFullYear();
    const lastYear = new Date().getFullYear()-1;
    const year_before_lastYear = new Date().getFullYear()-2;


    const columns = [
        { field: 'id', headerName: 'ID', type: 'number', width: 80 },
        { field: 'betrag', headerName: 'Betrag (EUR)', width: 150, type: 'number', valueFormatter: (params) => formatCurrency(params.value), },
        { field: 'buchungsdatum', headerName: 'Buchungsdatum', width: 150, type: 'date', valueFormatter: (params) => formatDate(params), align: 'center' },
        { field: 'paechter', headerName: 'Pächter', width: 230 },
        { field: 'zahlungsart', headerName: 'Zahlungsart', width: 200 },
        { field: 'zahlungsgrund', headerName: 'Zahlungsgrund', width: 200 },
        { field: 'kontierungsnummer', headerName: 'Kontierungsnummer', width: 200 },
        { field: 'hinweis', headerName: 'Hinweis', width: 150 },
    ];
    
    return (
        <>
        
        <AlertMessage
            message={alertMessage}
            severity={alertSeverity}
            isOpenAlert={openAlert === true}
            handleCloseAlertButton={() => { setOpenAlert(false); }}
        />

        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Title>Zahlungen {currentYear}</Title>

                    <Box
                        height={10}
                        my={3}
                        display="flex"
                        alignItems="center"
                        gap={1}
                        p={2.5}
                        sx={{ border: '2px solid rgba(0, 0, 0, 0.1)', width: 'max-content', 'border-radius': '6px' }}
                        >
                        Endsaldo {lastYear}: EUR {formatCurrency(endsaldoVorjahr)}
                    </Box>

                    <Box
                        height={10}
                        my={3}
                        display="flex"
                        alignItems="center"
                        gap={1}
                        p={2.5}
                        sx={{ border: '2px solid rgba(0, 0, 0, 0.1)', width: 'max-content', 'border-radius': '6px' }}
                        >
                        Summe Zahlungen: EUR {formatCurrency(totalBetragThisYear)}
                    </Box>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'buchungsdatum', sort: 'desc' }],
                                    },
                                }}
                                
                                rows={zahlungsListThisYear}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                loading={loadingOfGetZahlungenOfPachtThisYear}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                            />
                        </ThemeProvider>
                    </div>
                    
                </Grid>
            </Grid>
        </Container>



        <Container maxWidth="xl" sx={{ mt: 4, mb: 4, marginTop: 15 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Title>Zahlungen {lastYear}</Title>

                    <Box
                        height={10}
                        my={3}
                        display="flex"
                        alignItems="center"
                        gap={1}
                        p={2.5}
                        sx={{ border: '2px solid rgba(0, 0, 0, 0.1)', width: 'max-content', 'border-radius': '6px' }}
                        >
                        Endsaldo {year_before_lastYear}: EUR {formatCurrency(endsaldoVorvorjahr)}
                    </Box>

                    <Box
                        height={10}
                        my={3}
                        display="flex"
                        alignItems="center"
                        gap={1}
                        p={2.5}
                        sx={{ border: '2px solid rgba(0, 0, 0, 0.1)', width: 'max-content', 'border-radius': '6px' }}
                        >
                        Summe Zahlungen: EUR {formatCurrency(totalBetragLastYear)}
                    </Box>

                    <div style={{ width: '100%' }}>
                        
                        <ThemeProvider theme={lighttheme}>
                            <DataGrid
                                sx={{
                                    'border-radius': '6px',
                                    background: '#ffffff',
                                    'box-shadow': 'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
                                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                      },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 25, page: 0 },
                                    },
                                    sorting: {
                                        sortModel: [{ field: 'buchungsdatum', sort: 'desc' }],
                                    },
                                }}
                                
                                rows={zahlungsListLastYear}
                                columns={columns}
                                pageSize={3}
                                autoHeight={true}
                                rowsPerPageOptions={[3]}
                                pageSizeOptions={[10, 15, 25, 50, 100]}
                                loading={loadingOfGetZahlungenOfPachtLastYear}
                                checkboxSelection={false}
                                hideFooterSelectedRowCount={true}
                            />
                        </ThemeProvider>
                    </div>
                    
                </Grid>
            </Grid>
        </Container>


        </>
    );

}
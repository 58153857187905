import React from 'react';
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Title from '../Utils/Title';

import { GET_NEBENKOSTENSUMME_OF_YEAR } from './graphql';

import LoadingAnimation from '../Utils/LoadingAnimation';

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from 'recharts';






const CustomTooltipNebenkostensummen = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ background: 'white', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                <p>Summe Nebenkostenrechnungen für {label}</p>
                {payload.map((entry, index) => {
                    let unit = 'EUR';

                    // Format value with thousands separator and ensure two decimal places
                    const formattedValue = new Intl.NumberFormat('de-DE', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(entry.value);

                    return (
                        <p key={`item-${index}`} style={{ color: entry.color }}>
                            {unit} {formattedValue}
                        </p>
                    );
                })}
            </div>
        );
    }

    return null;
};






const Nebenkosten = () => {

    // Nebenkostensumme Vorvorjahr
    const yearBeforelastYear = new Date().getFullYear() - 2;
    const {
        loading: loadingNebenkostensummeYearBeforeLastYear,
        error: errorNebenkostensummeYearBeforeLastYear,
        data: dataNebenkostensummeOfYearBeforeLastYear,
    } = useQuery(GET_NEBENKOSTENSUMME_OF_YEAR, {
        variables: { requestedYear: yearBeforelastYear },
    });

       
    // Nebenkostensumme Vorjahr
    const lastYear = new Date().getFullYear() - 1;
    const {
        loading: loadingNebenkostensummeLastYear,
        error: errorNebenkostensummeLastYear,
        data: dataNebenkostensummeOfLastYear,
    } = useQuery(GET_NEBENKOSTENSUMME_OF_YEAR, {
        variables: { requestedYear: lastYear },
    });

    
    // Nebenkostensumme akutelles Jahr
    const currentYear = new Date().getFullYear();
    const {
        loading: loadingNebenkostensummeCurrentYear,
        error: errorNebenkostensummeCurrentYear,
        data: dataNebenkostensummeOfCurrentYear,
    } = useQuery(GET_NEBENKOSTENSUMME_OF_YEAR, {
        variables: { requestedYear: currentYear },
    });

    


    if (loadingNebenkostensummeCurrentYear || loadingNebenkostensummeLastYear || loadingNebenkostensummeYearBeforeLastYear) {
        return <LoadingAnimation />;
    }

    if (errorNebenkostensummeCurrentYear || errorNebenkostensummeLastYear || errorNebenkostensummeYearBeforeLastYear) {
        return (
            <div>
                {errorNebenkostensummeCurrentYear && <p>Error: {errorNebenkostensummeCurrentYear.message}</p>}
                {errorNebenkostensummeLastYear && <p>Error: {errorNebenkostensummeLastYear.message}</p>}
                {errorNebenkostensummeYearBeforeLastYear && <p>Error: {errorNebenkostensummeYearBeforeLastYear.message}</p>}
            </div>
        );
    }


    const nebenkostensummeOfCurrentYear = dataNebenkostensummeOfCurrentYear?.nebenkostensummeOfYear || 0;
    const nebenkostensummeOfLastYear = dataNebenkostensummeOfLastYear?.nebenkostensummeOfYear || 0;
    const nebenkostensummeOfYearBeforeLastYear = dataNebenkostensummeOfYearBeforeLastYear?.nebenkostensummeOfYear || 0;
    const nebenkostensummen = [];
    nebenkostensummen.push({"jahr": yearBeforelastYear, "Nebenkostensumme(EUR)": nebenkostensummeOfYearBeforeLastYear});
    nebenkostensummen.push({"jahr": lastYear, "Nebenkostensumme(EUR)": nebenkostensummeOfLastYear});
    nebenkostensummen.push({"jahr": currentYear, "Nebenkostensumme(EUR)": nebenkostensummeOfCurrentYear});


    return (

        <Paper sx={{ p: 6, m:0, width: '45%', display: 'inline-block', flex: '0 0 575px' }}>
            <Title>Nebenkostenrechnungen</Title>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={nebenkostensummen}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="jahr" />
                    <YAxis />
                    <Tooltip content={<CustomTooltipNebenkostensummen />} />
                    <Legend />
                    <Bar dataKey="Nebenkostensumme(EUR)" fill="#009999" />
                </BarChart>
            </ResponsiveContainer>
        </Paper>

    );
};

export default Nebenkosten;
